<template>
    <div class="ep-login-view-container">
        <div class="ep-login-view">
            <div class="login-top">
                <img :src="organizationInfo.logo" alt="">
            </div>
            <div class="login-center">
                <img :src="organizationInfo.projectBackground " alt="">
                <div class="lg-container">
                    <div class="lg-c-content">
                        <div class="lgc-c-introduce">
                          <img :src="organizationInfo.namePicture" alt=""><span>欢迎登录</span>
                        </div>
                        <div class="top-nav-bar">
                          <div class="nar-bar-item" :class="{'active': loginType == 1}" @click="changeLoginType(1)">账号登录</div>
                          <div class="nar-bar-line"></div>
                          <div class="nar-bar-item" :class="{'active': loginType == 2}" @click="changeLoginType(2)">短信登录</div>
                        </div>
                        <div class="form-content" v-if="loginType == 1">
                          <el-input v-model="operateObj.account" class="lgcc-o-inp"
                                    placeholder="用户名/身份证号/手机号"></el-input>
                          <el-input v-model="operateObj.password" class="lgcc-o-inp" placeholder="请输入密码" show-password></el-input>
                          <div class="lgcc-o-rememberMe">
                              <el-checkbox v-model="operateObj.rememberMe">记住我</el-checkbox>
                          </div>
                          <div class="lgcc-o-btn">
                            <el-button class="lgcc-o-save" round :loading="saveLoading" @click="loginC">登录</el-button>
                              <div class="lgcc-o-forget_password">
                                  <div>
                                    <span style="cursor: pointer;" @click="registerUser" v-if="redirectUrl && redirectUrl.includes('sites')">注册</span>
                                  </div>
                                  <span style="cursor: pointer;" @click="rememberPassword">忘记密码</span>
                              </div>
                          </div>
                          <div class="lgcc-o-privacy">登录视为您已同意 <b @click="privacyC(1)">【隐私政策】</b> <b @click="privacyC(2)">【用户协议】</b></div>
                        </div>
                        
                        <el-form ref="smsForm" :model="smsForm" :rules="smsFormRules" label-width="0px" class="form-content-form" v-show="loginType == 2">
                          <div class="form-content" style="height: 100%">
                            <el-form-item prop="phone">
                              <div class="form-content-item">
                                  <el-input
                                    placeholder="请输入手机号"
                                    v-model="smsForm.phone"
                                    class="phone-form">
                                    <!-- <i slot="prefix" class="el-input__icon el-icon-phone"></i> -->
                                    <img src="@/static/images/call.png" class="icon-prefix" alt="" slot="prefix">
                                  </el-input>
                              </div>
                            </el-form-item>
                            <el-form-item prop="sms">
                              <div class="form-content-item">
                                <div class="sms-box">
                                  <el-input
                                    placeholder="请输入验证码"
                                    v-model="smsForm.sms"
                                    maxlength="4"
                                    class="sms-form">
                                    <!-- <i slot="prefix" class="el-input__icon el-icon-phone"></i> -->
                                    <img src="@/static/images/Shield.png" class="icon-prefix1" alt="" slot="prefix">
                                  </el-input>
                                  <div class="btn-sms">
                                    <el-button type="primary" @click="getCode" :disabled="isDisabled">{{timeText}}</el-button>
                                  </div>
                                </div>
                              </div>
                            </el-form-item>
                            <div style="height: 26px;">
                            </div>
                            <div class="palce-box" v-if="false">
                            </div>
                            <div class="lgcc-o-btn">
                              <el-button class="lgcc-o-save" round :loading="saveLoading" @click="loginC">登录</el-button>
                                <div class="lgcc-o-forget_password">
                                    <div>
                                      <span style="cursor: pointer;" @click="registerUser" v-if="redirectUrl && redirectUrl.includes('sites')">注册</span>
                                    </div>
                                    <span style="cursor: pointer;" @click="rememberPassword">忘记密码</span>
                                </div>
                            </div>
                              <div class="lgcc-o-privacy">登录视为您已同意 <b @click="privacyC(1)">【隐私政策】</b> <b @click="privacyC(2)">【用户协议】</b></div>
                          </div>
                        </el-form>
                    </div>
                </div>
            </div>
                <footer-bootom :itemQueryP="itemQuery"/>
                <!-- <div class="footer-container">
                    <div class="f-text">
                        <div class="ft-phone-domain">
                            <span class="ft-p">{{organizationInfo.copyrightNotice}}</span>
                            <a style="color: white;margin-left:21px" href="https://beian.miit.gov.cn/" target="_blank">{{organizationInfo.icpCode}}</a>
                        </div>
                        <div class="ft-address" style="margin-top:8px">
                            <a style="color: white;" :href="'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode='+ organizationInfo.beianCode" target="_blank"><img class="pull-left" :src="imgObj.jingH" alt="">{{organizationInfo.beianContent}}</a>
                        </div>
                    </div>
                    <div class="f-qr_code" v-if="false">
                        <img :src="imgObj.qrCode">
                        <div class="fq-describe">
                            <span>高效管理</span>
                            <span>专业成长</span>
                        </div>
                    </div>
                </div> -->
            </div>
        <div class="bg-img"></div>
<!--        <remote-js :src="'https://pv.sohu.com/cityjsonx?timestamp=' + new Date().getTime()"></remote-js>-->
        <el-dialog
          title="滑动验证码"
          :visible.sync="smsDialogVisible"
          width="25%"
          center
          :before-close="() => smsDialogVisible = false"
          :close-on-click-modal="false"
          class="smsDialog"
        >
        <div class="dialog-content-sc" v-if="smsDialogVisible">
          <div id="sc" class="scent"></div>
        </div>
      </el-dialog>
      <el-dialog
                :title="drawerObj.title"
                :visible.sync="drawerObj.show"
                :size="500"
                center
                width="50%">
            <div class="drawer-container" v-html="drawerObj.html"></div>
      </el-dialog>
        <el-dialog
                :visible.sync="loginOutHint.show"
                width="280px"
                :show-close="false"
                top="18vh"
                center>
            <div class="loh-title" slot="title">提示</div>
            <div class="login-out-hint">
                <div class="loh-content">登录状态失效，请重新登录。</div>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" class="loh-btn" @click="closeLoginOut">重新登录</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {userLogin, getItemByQuery,getLoginInfo, sendPhoneVerificationCode,queryTheLatestData,getUserAgreementTheLatestData, getMyProjectPermissionOrganizationDto} from "@/views/login/service";
    import CryptoJS from 'crypto-js' //加密js
    import Cookies from 'js-cookie';
    import comm from "utils/comm";
    import utils from 'utils/util'
    import Cookie from 'js-cookie'
    import request from '@/utils/request'
    import footerBootom from "../login/footerBottom"

    export default {
        components:{
            footerBootom
            // 'remote-js': {
            //     render(createElement) {
            //         return createElement('script', { attrs: { type: 'text/javascript', src: this.src }});
            //     },
            //     props: {
            //         src: { type: String, required: true },
            //     },
            // }
        },
        data() {
          const validatePhone = (rule, value, callback) => {
            if (!value) {
              callback(new Error('请输入手机号'));
            } else {
              let reg = /^1[3-9]\d{9}$/
              if (!reg.test(value)) {
                callback(new Error('手机号格式不正确'));
              } else {
                callback();
              }
            }
          };
            return {
                loginOutHint:{
                  show:false
                },
                drawerObj:{
                  title:'隐私政策',
                  show:false,
                  direction:'btt',
                  html:'',
                  privacyData:'',
                  privacyUser:''
                },
                showObj: {
                    company: '强师',
                    domain: 'WWW.BESTEACHER.COM',
                    icpCode:''
                },
                imgObj: {
                    loginCenter: require('@/views/login/assets/login-center.png'),
                    loginIntroduce: require('@/views/login/assets/login-introduce.png'),
                    qrCode: require('@/views/login/assets/qrCode.png'),
                    jingH:require('@/assets/jinghui.png')
                },
                operateObj: {
                    account: '',
                    password: '',
                    rememberMe: false
                },
                saveLoading:false,
                organizationInfo:{
                    // loginLogo:require('@/views/login/assets/loginTop.png')
                },
                // 重定向url
                redirectUrl: null,
                ipAddress: '',
                // 登录类型
                loginType: 1,
                // 短信验证表单
                smsForm: {},
                // 只能滑动验证Data
                awscData: {},
                // 滑动验证flag
                awscFlag: false,
                smsFormRules: {
                  phone: [
                    { validator: validatePhone, trigger: 'blur' }
                  ],
                  sms: [
                    { required: true, message: '请填写验证码', trigger: 'blur' },
                  ]
                },
                // sms滑动验证码弹窗
                smsDialogVisible: false,
                // 验证码文字
                timeText: '获取验证码',
                // 按钮是否禁用
                isDisabled: false,
                time: 60,
                itemQuery:{},
                pwdKey: CryptoJS.enc.Utf8.parse('7kodHIX96fTW9auc'),
                ivKey: CryptoJS.enc.Utf8.parse('dxdcD8WWJPdWUQHx'),
                allMyProjectMenuArr: []
            }
        },
        created() {
            if (this.$route.query.redirectUrl) {
              this.redirectUrl = decodeURIComponent(this.$route.query.redirectUrl)
              console.log('this.redirectUrl: ',this.redirectUrl)
            }
            //绑定enter键登陆
            document.onkeydown = (event) => {
                let e = event || window.event;
                if (e && e.keyCode == 13) {
                    this.loginC();
                }
            };

            let obj = {
                passportSiteUrl: comm.getHost()
            }
            getItemByQuery(obj).then((res) => {
                console.log(res)
                if(res && res.data){
                    this.organizationInfo = res.data;
                    this.changeProjectTitle(res.data);
                    this.saveItemByQuery(res.data);
                    this.itemQuery = res.data;
                    let token = Cookie.get('access_token')
                    if(token){
                        this.getLoginInfo();
                    }
                }
            })

            // let size = utils.getRootDomain()?utils.getRootDomain():'localhost';
            // let itemByQuery = Cookies.get('itemByQuery',{domain:size});
            // if(itemByQuery){
            //     itemByQuery = JSON.parse(itemByQuery);
            //     this.organizationInfo = itemByQuery;
            //     this.changeProjectTitle(itemByQuery);
            //     this.itemQuery = itemByQuery;
            //     let token = Cookie.get('access_token')
            //     if(token){
            //          this.getLoginInfo();
            //     }
            // }
            this.getIpAddress()
        },
        mounted() {
            this.getCookie();
            this.queryTheLatestData();
            this.getUserAgreementTheLatestData();
            if(this.operateObj.password){
                this.operateObj.rememberMe = true;
            }
            if(Cookie.get('loginHint')){
                this.loginOutHint.show = true;
            }
        },
        methods: {
          registerUser () {
            if (process.env.NODE_ENV === 'production') {
              window.location.href = 'https://h5.besteacher.com.cn/?id=' + (this.organizationInfo ? this.organizationInfo.id : 0) + '&href=' + this.redirectUrl
            } else {
              window.location.href = 'https://h5-test-1225.besteacher.com.cn/?id=' + (this.organizationInfo ? this.organizationInfo.id : 0) + '&href=' + this.redirectUrl
            }
          },
        closeLoginOut(){
            let size = utils.getRootDomain()?utils.getRootDomain():'localhost';
            this.loginOutHint.show = false;
            Cookies.remove('loginHint', {domain:size});
        },
         getUserAgreementTheLatestData(){
                getUserAgreementTheLatestData().then(res=>{
                    if(res && res.content){
                        this.drawerObj.privacyUser = res.content;
                    }
                }).catch(res=>{
                    if(res && res.content){
                        this.drawerObj.privacyUser = res.content;
                    }
                })
         },
         queryTheLatestData(){
             queryTheLatestData().then(res=>{
                 if(res && res.content){
                     this.drawerObj.privacyData = res.content;
                 }
             }).catch(res=>{
                 if(res && res.content){
                     this.drawerObj.privacyData = res.content;
                 }
             })
         },
          //隐私政策管理
          privacyC(type){
             this.drawerObj.title = type===1?'隐私政策':'用户协议';
             this.drawerObj.show = true;
             if(type===1){
                 this.drawerObj.html = this.drawerObj.privacyData;
             }else{
                 this.drawerObj.html = this.drawerObj.privacyUser;
             }
          },
          /**
           * 获取验证码倒计时60s
           */
          intervalTime() {
            let s = this.time
            this.isDisabled = true
            this.timeText = `${s}秒后可重发`
            let interval = setInterval(() => {
              s--
              this.timeText = `${s}秒后可重发`
              if (s < 0) {
                this.timeText = '重新获取验证码'
                this.isDisabled = false
                clearInterval(interval)
              }
            }, 1000)
          },
          /**
           * 获取验证码
           */
          getCode() {
            this.$refs.smsForm.validateField('phone')
            let reg = /^1[3-9]\d{9}$/
            if (!reg.test(this.smsForm.phone)) return
            this.smsDialogVisible = true
            this.$nextTick(() => {
              this.initAwsIc()
            })
          },
          // 发送短信验证码
          sendHandleVerifyCode() {
            console.log('??????')
            if (!this.isDisabled) {
              const params = {
                phone: this.smsForm.phone,
                ...this.awscData,
                scene: "ic_message_h5",
                loginFlag: true,
                'smsType':3,
              }
              sendPhoneVerificationCode(params).then(res => {
                console.log('pppp: ',res)
                if (res.code == 200) {
                  this.intervalTime()
                  this.smsDialogVisible = false
                }
                console.log('params', params)
              }).catch(err => {
                this.$message.error(err.message)
                  this.smsDialogVisible = false
              })
            }
          },
          initAwsIc() {
            const that = this;
            // 实例化ic对象
            AWSC.use("ic", function (state, module) {
              // 初始化
              window.ic = module.init({
                  // 应用类型标识。它和使用场景标识（scene字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
                  appkey: "FFFF0N0N00000000B11D",
                  // 使用场景标识。它和应用类型标识（appkey字段）一起决定了智能验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的scene值，请务必正确填写。
                  scene: "ic_message_h5",
                  // 声明智能验证需要渲染的目标元素ID。
                  renderTo: 'sc',
                  width: '100%',
                  height: 50,
                  // 测试参数
                  // test: module.TEST_NC_BLOCK,
                  // 验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
                  success: (data) => {
                    setTimeout(() => {
                      that.awscData = data
                      that.sendHandleVerifyCode(data)
                    }, 500)
                    // accountAfsSuccess.value = true
                    // window.console && console.log(data.sessionId)
                    // window.console && console.log(data.sig)
                    // window.console && console.log(data.token)
                  },
                  // 验证失败时触发该回调参数
                  fail: function (failCode) {
                      console.log(failCode)
                  },
                  // 验证码加载异常时触发该回调参数
                  error: function (errorCode) {
                      console.log(errorCode)
                  }
              });
            })
          },
          /**
           * 切换登录方式
           */
          changeLoginType(type) {
            this.loginType = type
            if (type == 2) {
              this.$nextTick(() => {
                this.$refs.smsForm.clearValidate()
              })
            }
          },
          // 获取ip地址
          getIpAddress() {
            request({
              url: 'https://api.ipify.org?format=json',
              method: 'get',
            }).then(res => {
              if (res && res.data) {
                const { ip } = res.data
                this.ipAddress = ip
              }
            })
          },
          // 忘记密码
          rememberPassword() {
            this.$alert('联系本校管理员或平台负责人','温馨提示', {
              confirmButtonText: '确定',
            });
          },
            //如果token已存在，直接获取登录信息跳转对应的页面
            getLoginInfo(){
                getLoginInfo().then(res=>{
                    let userinfo = res.data.userinfo;
                    sessionStorage.setItem("userinfo", JSON.stringify(userinfo));
                    userinfo.permissionDto = res.data.permissionDto || {};
                    this.saveTokenUser(res.data.token,res.data?.userinfo?.userId);
                    if(!this.organizationInfo.id){
                        this.$message.error('获取项目信息失败，请联系管理员!')
                        return;
                    }
                    this.routeFun(res)
                }).catch(error=>{
                    let size = utils.getRootDomain()?utils.getRootDomain():'localhost';
                    sessionStorage.clear();
                    Cookies.remove('access_token',{domain:size});
                })
            },
            getAll(arr) {
              arr.forEach(e => {
                this.allMyProjectMenuArr.push(e.frontPath) 
                if(e.organizationPermissionDtos && e.organizationPermissionDtos.length){
                  this.getAll(e.organizationPermissionDtos);
                }
              });
            },
            routeFun(res) {
              if(!res.data.userinfo.forceFlag){ //强制完善信息
                this.$router.replace({
                  path: '/finishInfo',
                  query: {
                    redirectUrl: this.$route.query.redirectUrl?this.$route.query.redirectUrl:''
                  }
                })
                return
              } else if (res.data.userinfo.multiSiteFlag) { //用户属于多站点
                this.$router.replace({
                  path: '/organizationList',
                })
                return
                // window.open(`${this.organizationInfo.archivesSiteUrl}/organizationList`, '_self')
              }
              if (!res.data.organizationPermissionDto) {
                let obj = {
                    organizationId: res.data.userinfo.organizationId,
                    roleId: res.data.userinfo.orgUserInfoDetails?[0].roleId:res.data.userinfo.roleId
                }
                getMyProjectPermissionOrganizationDto(obj).then(res => {
                    console.log(res)
                    this.myProjectMenuList = res.data?res.data.organizationPermissionDtos : []
                    if (this.myProjectMenuList.length) {
                      this.getAll(this.myProjectMenuList)
                      window.open(`${this.organizationInfo.archivesSiteUrl}${this.allMyProjectMenuArr[0]}`, '_self')
                      return
                    }
                })
              }
              if (res.data.organizationPermissionDto && res.data.organizationPermissionDto.organizationPermissionDtos.length &&
                res.data.organizationPermissionDto.organizationPermissionDtos[0].code !=='org_manage_data_cockpit') {
                this.getAll(res.data.organizationPermissionDto.organizationPermissionDtos)
                window.open(`${this.organizationInfo.archivesSiteUrl}${this.allMyProjectMenuArr[0]}`, '_self')
                return
              }
              let roleLevel = res.data.userinfo.roleLevelInt !== -1 ? res.data.userinfo.roleLevelInt : res.data.userinfo.orgUserInfoDetails[0].orgRoleLevelInt
              if (roleLevel == 0) {
                window.open(`${this.organizationInfo.manageSiteUrl}/home/pm/page`, '_self')
              } else if (roleLevel == 1) { //顶级管理员顶级 1
                window.open(`${this.organizationInfo.archivesSiteUrl}/cockpit/topLevel`, '_self')
                return
              } else if (roleLevel >=2 && roleLevel <= 6) { //中间
                window.open(`${this.organizationInfo.archivesSiteUrl}/cockpit/middleLevel`, '_self')
                return
              } else if (roleLevel >=7 && roleLevel <= 12) { //学科
                window.open(`${this.organizationInfo.archivesSiteUrl}/cockpit/subjectleLevel`, '_self')
                return
              } else if (roleLevel == 13) { //校管
                window.open(`${this.organizationInfo.archivesSiteUrl}/cockpit/schoolLevel`, '_self')
                return
              } else if (roleLevel == 14 || roleLevel == 15) { //组长、教师跳转到我的项目页面
                window.open(`${this.organizationInfo.archivesSiteUrl}/cockpit/selectionProject`, '_self')
                return
              } else {
                window.open(`${this.organizationInfo.archivesSiteUrl}/cockpit`, '_self')
              }
            },
            Decrypt(word) {
              let key = this.pwdKey;
              let iv = this.ivKey;
              let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
              let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
              var decrypt = CryptoJS.AES.decrypt(srcs, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
              });
              let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
              return decryptedStr.toString();
            },
            Encrypt(word) {
              let key = this.pwdKey;
              let iv = this.ivKey;
              var encrypted = CryptoJS.AES.encrypt(word, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
              });
              return encrypted.ciphertext.toString().toUpperCase();
            },
            async loginC() {
                let obj = {}
                if (this.loginType == 1) {
                  obj = {
                      'loginName': this.operateObj.account,
                      password: this.Encrypt(this.operateObj.password),
                      // organizationId: 450
                      // organizationId:445
                      organizationId: this.organizationInfo ? this.organizationInfo.id : 0
                  }
                } else {
                  try{
                    await this.$refs.smsForm.validate()
                  } catch(e) {
                    console.log(e)
                    return
                  }
                  obj = {
                    phone: this.smsForm.phone,
                    verificationCode: this.smsForm.sms,
                    'smsType':3,
                    // organizationId: 450
                    // organizationId:164
                      organizationId: this.organizationInfo ? this.organizationInfo.id : 0
                  }
                }
                console.log(obj ,'obj')
                this.saveLoading = true;

                this.$parent.systemNoticeGet();
                userLogin(obj).then((res) => {
                    if (res.data && !comm.isEmpty(res.data.token)) {
                        let userinfo = res.data.userinfo;
                        let loginInfo = {
                          loginName: this.operateObj.account,
                          password: this.operateObj.password,
                          loginType: this.loginType,
                          phone: this.smsForm.phone,
                          verificationCode: this.smsForm.sms
                        }
                        sessionStorage.setItem("userinfo", JSON.stringify(userinfo));
                        sessionStorage.setItem("loginInfo", JSON.stringify(loginInfo));
                        userinfo.permissionDto = res.data.permissionDto || {};
                        this.saveTokenUser(res.data.token,res.data?.userinfo?.userId);
                        this.saveLoading = false;

                        if(!this.organizationInfo.id){
                            this.$message.error('获取项目信息失败，请联系管理员!')
                            return;
                        }

                        if (this.redirectUrl) {
                          console.log(this.redirectUrl)
                          let fomart = this.organizationInfo.communitySiteUrl + `${this.redirectUrl}`
                          if (decodeURIComponent(this.redirectUrl)=='/site/scoring/eventManger/') {
                            fomart = this.organizationInfo.manageSiteUrl + `${this.redirectUrl}`
                          } else if (window.location.href.includes('sites')) {
                            fomart = this.redirectUrl
                          }
                          location.href = fomart;
                          return
                        }
                        if (this.operateObj.rememberMe == true) {
                            //传入账号，密码，保存天数
                            this.setCookie(this.operateObj.account, this.operateObj.password, 7);
                        } else {
                            //清空Cookie
                            this.clearCookie();
                        }
                        // ________________________________________________________
                        this.routeFun(res)
                    } else {
                      this.saveLoading = false;
                    }
                }).catch((rej)=>{
                    this.$message.error(rej.message);
                    this.saveLoading = false;
                })
            },
            //设置cookie方法
            setCookie(mobile, password, days) {
                let text = CryptoJS.AES.encrypt(password, 'secret key 123');//使用CryptoJS方法加密
                let saveDays = new Date(); //获取时间
                saveDays.setTime(saveDays.getTime() + 24 * 60 * 60 * 1000 * days); //保存的天数
                //字符串拼接存入cookie
                window.document.cookie = "mobile" + "=" + mobile + ";path=/;expires=" + saveDays.toGMTString();
                window.document.cookie = "password" + "=" + text + ";path=/;expires=" + saveDays.toGMTString();
            },
            //读取cookie
            getCookie() {
                if (document.cookie.length > 0) {
                    let arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
                    for (let i = 0; i < arr.length; i++) {
                        let arr2 = arr[i].split('='); //再次切割
                        //这里会切割出以mobile为第0项的数组、以password为第0项的数组，判断查找相对应的值
                        if (arr2[0] == 'mobile') {
                            this.operateObj.account = arr2[1]; //拿到账号
                        } else if (arr2[0] == 'password') {
                            //拿到拿到加密后的密码arr2[1]并解密
                            let bytes = CryptoJS.AES.decrypt(arr2[1].toString(), 'secret key 123');
                            let plaintext = bytes.toString(CryptoJS.enc.Utf8); //拿到解密后的密码（登录时输入的密码）
                            this.operateObj.password = plaintext;
                        }
                    }
                }
            },
            //清除cookie
            clearCookie() {
                this.setCookie("", "", 0); //账号密码置空，天数置0
            },
            //将token保存在cookie中
            saveTokenUser(token,userId){
                let saveDays = new Date(); //获取时间
                saveDays.setTime(saveDays.getTime() + 24 * 60 * 60 * 1000 * 2); //保存的天数
                let size = utils.getRootDomain()?utils.getRootDomain():'localhost';
                //字符串拼接存入cookie
                window.document.cookie = "access_token" + "=" + token + ";path=/;expires=" + saveDays.toGMTString()+";domain="+size;
                window.document.cookie = "userId" + "=" + userId + ";path=/;expires=" + saveDays.toGMTString()+";domain="+size;
                sessionStorage.setItem("token", token);
                sessionStorage.setItem("userId", userId);
            },
            saveItemByQuery(data){
                let saveDays = new Date(); //获取时间
                saveDays.setTime(saveDays.getTime() + 24 * 60 * 60 * 1000 * 3); //保存的天数
                let size = utils.getRootDomain()?utils.getRootDomain():'localhost';
                let str = JSON.stringify(data)
                if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
                  str = encodeURI(str)
                }
                //字符串拼接存入cookie
                window.document.cookie = "itemByQuery" + "=" + str + ";path=/;expires=" + saveDays.toGMTString()+";domain="+size;
            },
            //更改项目标题和图标
            changeProjectTitle(data){
                document.title = data.name;
                if(data.favicon){
                    let $favicon =  document.querySelector("link[rel*='icon']");
                    console.log($favicon);
                    if(!$favicon){
                        $favicon = document.createElement('link');
                        $favicon.setAttribute('rel','shortcut icon');
                        $favicon.setAttribute('type','image/x-icon')
                        $favicon.href = data.favicon;
                        document.head.appendChild($favicon);
                    }else{
                        $favicon.type = 'image/png';
                        $favicon.href = data.favicon;
                    }
                }
            },

        }
    }
</script>

<style scoped lang="scss">
.drawer-container{
    height: 600px;
    overflow-y: auto;
    padding: 0 40px;
}
.el-checkbox.is-checked {
  ::v-deep .el-checkbox__label {
    color: #16b4f4;
  }
  ::v-deep .el-checkbox__inner {
    background-color: #16b4f4;
    border-color: #16b4f4;
  }
}
</style>