import {Message} from 'element-ui';
import Cookies from 'js-cookie'
let util = {};

util.errorMessage = function(message){
    Message({
        showClose: true,
        message: message,
        type: 'error'
    });
}

util.exit = function(){
  sessionStorage.clear();
  Cookies.remove('access_token');
  let itemByQuery = Cookies.get('itemByQuery');
  if(itemByQuery){
      itemByQuery = JSON.parse(itemByQuery);
  }else{
      process.env.NODE_ENV === 'production'?(itemByQuery = {passportSiteUrl:'https://login.besteacher.com.cn/login'}):(itemByQuery = {passportSiteUrl:'https://login-test-1225.besteacher.com.cn/login'})
  }
  location.href = itemByQuery?.passportSiteUrl;
}
util.cloneDeep = function(obj){
    if(typeof obj !== "object" || obj==null){
        return obj;
    }
    let result;
    if(obj instanceof Array){
        result = [];
    }else{
        result = {};
    }
    for (let key in obj){
        if(obj.hasOwnProperty(key)){
            result[key] = util.cloneDeep(obj[key]);
        }
    }
    return result;
}

util.debounce = function(fn,wait){
    let time = null;
    return function(){
        if(time!==null)clearTimeout(time)
        time = setTimeout(function(){
            time = null;
            fn().call(this)
        },wait)
    }
}

util.isSystemStatus = function(){
    let systemMaintain = Cookies.get('systemMaintain');
    if(!systemMaintain){
        return false;
    }else{
        let obj = JSON.parse(systemMaintain);
        if(obj.type===0){
            return true;
        }else{
            return false;
        }
    }
}

util.throttle = function (fn,delay) {
    let prev = Date.now();
    return function(){
        let now = Date.now();
        if(now - prev >= delay){
            fn.call(this);
            prev = Date.now();
        }
    }
}
/*
* @params skipUrl:跳转路径
* @params data:传输数据
* @params transmitUrl:传输路径
*/
util.postMessage = function (skipUrl,data,transmitUrl) {
   let otherWindow = window.open(skipUrl),
        _this = this;
   setTimeout(function(){
       otherWindow.postMessage(JSON.stringify(data),transmitUrl || skipUrl)
       _this.closeWin();
   },800)
}

util.closeWin = function(){
    if (navigator.userAgent.indexOf("Firefox") != -1 || navigator.userAgent.indexOf("Chrome") !=-1) {
        open && open(location, '_self').close();
        window.location.href="about:blank";
        window.close();
    } else {
        window.opener = null;
        window.open("", "_self");
        window.close();
    }
}

util.getRootDomain = function () {
    let KEY = '__rT_dM__' + (+new Date());
    let R = new RegExp('(^|;)\\s*' + KEY + '=1');
    let Y1970 = (new Date(0)).toUTCString();

    let domain = document.domain || location.hostname;
    let list = domain.split('.');
    let len = list.length;
    let temp = '';
    let temp2 = '';
    while (len--) {
        temp = list.slice(len).join('.');
        temp2 = KEY + '=1;domain=.' + temp;
        // try to set cookie
        document.cookie = temp2;

        if (R.test(document.cookie)) {
            // clear
            document.cookie = temp2 + ';expires=' + Y1970;
            return temp;
        }
    }
}

export default util;