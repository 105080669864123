<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  import { systemNoticeGet } from '@/api/project'
  import {getItemByQuery} from "@/views/login/service";
  import Cookies from 'js-cookie'
  import util from "@/utils/util";
  import comm from "@/utils/comm";

  export default {
    data(){
      return {

      }
    },
    created() {
      let obj = {
        passportSiteUrl: comm.getHost()
      }
      getItemByQuery(obj).then((res) => {
        if(res && res.data){
          this.saveItemByQuery(res.data);
          this.systemNoticeGet();
        }
      }).catch((err)=>{
        if(err.code === -1 && this.$route.path.indexOf('/404')===-1){
          this.$router.push('/404')
        }
      })
    },
    methods:{
      saveItemByQuery(data){
        let saveDays = new Date(); //获取时间
        saveDays.setTime(saveDays.getTime() + 24 * 60 * 60 * 1000 * 3); //保存的天数
        let size = util.getRootDomain()?util.getRootDomain():'localhost';
        let str = JSON.stringify(data)
        if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
          str = encodeURI(str)
        }
        //字符串拼接存入cookie
        window.document.cookie = "itemByQuery" + "=" + str + ";path=/;expires=" + saveDays.toGMTString()+";domain="+size;
      },
      systemNoticeGet(){
        let itemByQuery = Cookies.get('itemByQuery'),
            strH = [271,150];
            itemByQuery = itemByQuery?JSON.parse(itemByQuery):{}
        systemNoticeGet().then(res=>{
          if(res && res.data){
            this.setCookies('systemMaintain',JSON.stringify(res.data),5*60)
            if(res.data.type === 0 && !strH.includes(itemByQuery.id)){
              this.$router.replace('/temporarily/closed')
            }else if(this.$route.path.indexOf('/selectionProject') === -1 && this.$route.path.indexOf('/scoreLogin') === -1){
              this.$router.replace('/login')
            }
          }else if(this.$route.path.indexOf('/selectionProject') === -1 && this.$route.path.indexOf('/scoreLogin') === -1){
            this.removeCookies('systemMaintain');
            if(this.$route.path.indexOf('/temporarily/closed') !== -1){
              this.$router.replace('/login')
            }
          }
        })
      },
      getCookies (key) {
        return Cookies.get(key)
      },
      setCookies (key, value, expiresTime) {
        let seconds = expiresTime;
        let expires = new Date(new Date() * 1 + seconds * 1000);
        let size = util.getRootDomain()?util.getRootDomain():'localhost';
        Cookies.set(key, value, { expires: expires,domain:size})
      },
      removeCookies (key) {
        let size = util.getRootDomain()?util.getRootDomain():'localhost';
        Cookies.remove(key,{domain:size})
      }
    }
  }
</script>
<style lang="scss">

</style>
