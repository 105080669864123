import axios from '@/utils/ajax';
import Global from "@/utils/global";

// 获取推荐给我的项目
export const getRecommendProject = (params)=>{
  return axios.ajax4UserMgt(Global.getParamsKey('BASE_URL3')+'/project/getRecommendProject','get',params, '', { needToken: true });
}
// 根据deepCode获取学校
export const getSchoolByDeepCode = (params)=>{
  return axios.ajax4UserMgt(Global.getParamsKey('BASE_URL3')+'/school/getSignUpSchoolList','get',params, '', { needToken: true });
}
// 获取项目下区域树
export const getAreaTreeAll1 = (params, proId)=>{
  return axios.ajax4UserMgt(Global.getParamsKey('BASE_URL3')+'/area/getSignUpAreaTree','get',params, '', { needToken: true });
}
//获取角色列表
export const getRoleList1 = (params, proId) =>{
  return axios.ajax4UserMgt(Global.getParamsKey('userapi')+'/roleProject/getSignUpRoleProject','get',params, '', { needToken: true });
}
// 自主报名
export const selfRegistration = (params) =>{
  return axios.ajax4UserMgt(Global.getParamsKey('userapi')+'/projectUser/selfRegistration','post',params, '', { needToken: true });
}
// 获取我的工作台
export const getOrganizationsIdByUserId = (params) =>{
  return axios.ajax4UserMgt(Global.getParamsKey('BASE_URL4')+'/organization/getOrganizationsIdByUserId','get',params, '', { needToken: true });
}

//进入项目
export const enterProject = (params)=>{
  return axios.ajax4UserMgt(Global.getParamsKey('userapi')+'/user/enterProject','post',params, '', { needToken: true })
}

//全局获取系统通知
export const systemNoticeGet = (params)=>{
  return axios.ajax4UserMgt(Global.getParamsKey('BASES_URL') + '/systemNotice/get','get',params);
}
// 用户退出登录
export const userLogout = (params)=>{
  return axios.ajax4UserMgt(Global.getBaseUrl() +'/user/logout','get',params, '', {
    needToken: true
  });
}