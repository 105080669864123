import axios from 'axios';
import util from '@/utils/util';
import global from '@/utils/global'
import Cookie from 'js-cookie'
const httpRequest = (resolve,reject,config,catchReject, custom)=>{
    axios(config).then(response =>{
        //请求接口返回正确时
        if(response.data.code === 200){
            resolve(response.data || {});
            //后续跟后端定义好返回状态码，来处理token过期和seesion过期情况
        } else if(response.data.code === 403 || response.data.code === 401){  //针对token过期，直接跳转到登录页
          if (custom.noExit) return
          util.errorMessage('长时间未操作，请重新登录!');
          util.exit();
        } else{
            if(catchReject){
                reject(response.data)
            }else{
                util.errorMessage(response.data.message || '系统异常，请求失败~~~');
            }
        }
    }).catch((err) => {
      err = err.toString();
        if(err.indexOf('403') != -1 || err.indexOf('401') != -1){  //针对token过期，直接跳转到登录页
          if (custom.noExit) return
            util.errorMessage('状态异常，请重新登录!');
            util.exit();
        }else{
            util.errorMessage(err.message || '系统异常，请求失败~~~');
        }
    })
}
/**
 * 参数
 * @param {*} url 
 * @param {*} method 
 * @param {*} params 
 * @param {*} catchReject 
 * @param {*} custom  扩展数据
 * @returns 
 */
axios.ajax4UserMgt = function ajax(url,method,params,catchReject, custom = {}){
    return new Promise((resolve,reject)=>{
        let config = null,
          token = Cookie.get('access_token')
        config = {
            url,
            method
        };
        config.headers = {
            'Content-type': 'application/json;charset=utf-8'
        };
        if(custom.needToken){
            config.headers['access-token'] = token;
        }
        if(method && (method.toLowerCase() === 'get' || method.toLowerCase() === 'put')){
            config.params = params;
        }else if(method.toLowerCase() === 'put1'){
            config.method = 'put';
            config.data = params;
        }else{
            config.data = params;
        }
        httpRequest(resolve,reject,config,catchReject,custom);
    })
};
export default axios;